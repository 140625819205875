.video-container-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding-top: 5vw;
  padding-bottom: calc(8vw + 35px);
  flex-wrap: wrap;
}

.video-container {
  width: 40vw;
  margin: 2vw;

  &__is-large {
    width: 75vw;
  }
}
