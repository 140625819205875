@use '../../styles/typography.scss';
@use '../../styles/breakpoints.scss';

.root {
    background-image: url('../../images/bg-circle.svg');
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    padding: 100px;
    margin: 0 -100px;

    p {
        font-size: 30px;
        line-height: (45 / 30);

        &:not(:last-child) {
            padding-bottom: 40px;
        }
    }

    b {
        font-weight: typography.$bold;
    }

    @include breakpoints.small {
        p {
            font-size: 20px;
        }
    }
}
