@function breakpoint($from: false, $to: false, $property: false) {
    $breakpoints: (
        mobileMax: 500px,
        tabletMax: 1000px,
    );

    @if ($breakpoints == false) {
        $breakpoints: ();
    }
    @if ($property != height) {
        $property: width;
    }

    @if (map-get($breakpoints, $from)) {
        $from: map-get($breakpoints, $from);
    }
    @if (map-get($breakpoints, $to)) {
        $to: map-get($breakpoints, $to);
    }

    @if ($from != false) {
        @if (
            type-of($from) !=
                number or
                type-of($from) ==
                number and
                unitless($from)
        ) {
            @error "breakpoint: Invalid value supplied to argument $from (#{$from})";
        }
    }

    @if ($to != false) {
        @if (
            type-of($to) != number or type-of($to) == number and unitless($to)
        ) {
            @error "breakpoint: Invalid value supplied to argument $from (#{$to})";
        }
    }

    @if ($from and $to) {
        @return 'min-' + $property + ': ' + ($from + 1) + ') and (max-' +
            $property + ': ' + $to;
    }
    @if ($from) {
        @return 'min-' + $property + ': ' + ($from + 1);
    }
    @if ($to) {
        @return 'max-' + $property + ': ' + $to;
    }
}

@mixin small {
    @media (breakpoint(false, mobileMax)) {
        @content;
    }
}

@mixin small-plus {
    @media (breakpoint(mobileMax)) {
        @content;
    }
}

@mixin medium {
    @media (breakpoint(mobileMax, tabletMax)) {
        @content;
    }
}

@mixin medium-plus {
    @media (breakpoint(mobileMax)) {
        @content;
    }
}

@mixin large {
    @media (breakpoint(tabletMax)) {
        @content;
    }
}
