@use '../../../styles/colors.scss';

.root__blue {
  fill: colors.$blue;
}

.root__white {
  fill: colors.$offwhite;
}

.root__black {
  fill: colors.$black--dark;
}
