@use '../../styles/colors.scss';
@use '../../styles/breakpoints.scss';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  &__padded {
    padding: 0 25px;

    @include breakpoints.small-plus {
      padding: 0 50px;
    }
  }

  &__heightAdjusted {
    min-height: 50vh;
  }

  &__blue,
  &__blueLogo {
    background-color: colors.$blue;
    color: colors.$white;
  }

  &__white {
    background-color: colors.$offwhite;
    color: colors.$blue;
  }

  &__black {
    background-color: colors.$black--dark;
    color: colors.$offwhite;
  }

  &__blueLogo {
    background-image: url('../../images/bg-logo.png');
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
  }

  @include breakpoints.medium-plus {
    &__heightAdjusted {
      min-height: 90vh;
    }
  }
}

.topSquiggle {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 1400px;
  background-image: url('../../images/header-squiggle--white.svg');
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
}

.inner {
  position: relative;
  z-index: 1;

  &__center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__dontCenter {
    width: 100%;
    flex-basis: 100%;
  }

  &__paddedVertical {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__small {
    width: 100%;
    max-width: 1000px;
    padding-left: 25px;
    padding-right: 25px;

    @include breakpoints.small-plus {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  > img {
    width: 100%;
    height: auto;
  }
}

.containerMask {
  width: 100vw;
  z-index: 2;

  path {
    width: 100%;
    min-width: 100%;
  }

  &__overlaidTop {
    position: absolute;
    top: 0;
    transform: translateY(-1px);
  }

  &__overlaidBottom {
    position: absolute;
    bottom: 0;
    transform: translateY(1px);
  }
}
