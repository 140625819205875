@use '../../styles/colors.scss';
@use '../../styles/typography.scss';
@use '../../styles/breakpoints.scss';

.root {
  display: inline-block;
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
  color: inherit;
  vertical-align: middle;

  &:hover {
    color: colors.$black;

    svg {
      display: block;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200%;
    height: 200%;
    transform: translate(-50%, -50%) scale(1.5);
    display: none;
    pointer-events: none;
  }

  path {
    stroke: colors.$black;
    stroke-width: 3px;
    fill: none;
  }
}

.label {
  font-size: 5vw;

  &,
  &:visited {
    color: inherit;
  }

  @include breakpoints.medium-plus {
    font-size: 4vw;
  }

  &--is-small {
    @include breakpoints.large {
      font-size: 2vw;
    }
  }
}
