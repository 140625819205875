@use '../../styles/breakpoints.scss';

$_width--l: (1000 / 1440) * 100vw;
$_width--s: 90%;

.root {
    display: flex;
    flex-direction: column;

    &:nth-child(even) {
        text-align: right;
        align-items: flex-end;
    }

    &:not(:last-child) {
        padding-bottom: 120px;
    }
}

.quote {
    width: $_width--l;
    font-size: 50px;
    margin: 0;
    padding-bottom: 20px;

    @include breakpoints.small {
        font-size: 20px;
        width: $_width--s;
    }

    @include breakpoints.medium {
        font-size: 35px;
    }
}

.source {
    width: $_width--l;
    font-size: 30px;
    margin: 0;

    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        width: (40 / 30) * 1em;
        height: (15 / 20) * 1em;
        margin-right: 0.5em;
        background-image: url('../../images/quote-line.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    @include breakpoints.small {
        font-size: 15px;
        width: $_width--s;
    }

    @include breakpoints.medium {
        font-size: 20px;
    }
}
