@use '../../styles/typography.scss';
@use '../../styles/breakpoints.scss';

.root {
    width: 100%;
    max-width: 1200px;
    padding-top: 100px;
    padding-bottom: 100px;
    margin: 0 auto;

    @include breakpoints.medium-plus {
        display: flex;
    }
}

.section {
    img {
        width: 100%;
        height: auto;
        box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.2);
    }
}

.coverSection {
    @include breakpoints.small {
        padding-bottom: 20px;
    }

    @include breakpoints.medium-plus {
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 40%;
    }
}

.audioSection {
    padding: 1vw 0;

    @include breakpoints.medium-plus {
        flex-shrink: 1;
        flex-grow: 1;
        padding-left: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    @include breakpoints.small-plus {
        padding-left: 25px;
    }

    @include breakpoints.large {
        padding-left: 50px;
    }
}

.tracks {
    list-style: none;
    margin: 0;
    padding: 0;
}

.track {
    cursor: pointer;

    &__selected {
        font-weight: typography.$bold;
    }

    @include breakpoints.medium-plus {
        font-size: 30px;
    }
}
