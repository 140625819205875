@use '../../styles/colors.scss';
@use '../../styles/animations.scss';
@use '../../styles/utils.scss';

.AudioPlayer {
    width: 100%;

    &__iframe {
        width: 0;
        overflow: hidden;
    }

    &__track {
        margin-top: 1.5rem;
        height: 20px;
        position: relative;
    }

    &__times {
        display: flex;
        justify-content: space-between;
        pointer-events: none;
    }

    &__time-start,
    &__time-end {
        font-size: 1rem;
        user-select: none;
    }

    &__runway,
    &__elapsed {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        pointer-events: none;
    }

    &__elapsed,
    &__handle {
        background-color: colors.$white;
        transition: background-color animations.$quick animations.$easing;

        .AudioPlayer__track:hover & {
            background-color: colors.$offwhite;
        }
    }

    &__runway {
        height: 2px;
        background-color: colors.$offwhite;
    }

    &__elapsed {
        height: 6px;
        width: 0;
        transform-origin: left;
        transition: width 100ms ease-out;
    }

    &__handle {
        position: absolute;
        height: 16px;
        width: 4px;
        right: 0;
        top: 50%;
        transform: translate(50%, -50%);
    }

    &__controls {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__prev,
    &__play-pause,
    &__next {
        @extend %remove-default-button-style;
        height: 2rem;
        width: 2rem;
        margin: 0 1.25rem;
        font-size: 20px;

        rect,
        path {
            stroke: colors.$white;
            transition: stroke animations.$quick animations.$easing;
        }

        rect {
            fill: colors.$white;
            transition: fill animations.$quick animations.$easing;
        }

        &:hover {
            rect,
            path {
                stroke: colors.$offwhite;
            }

            rect {
                fill: colors.$offwhite;
            }
        }

        &:focus {
            rect,
            path {
                stroke: colors.$offwhite;
            }

            rect {
                fill: colors.$offwhite;
            }
        }
    }

    &__play-pause {
        rect {
            fill: none !important;
        }
    }

    &__prev {
        transform: scaleX(-1);
    }
}
