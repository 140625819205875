@use '/src/styles/colors.scss';

* {
    box-sizing: border-box;

    &:focus {
        outline: none;
    }
}

:root {
    background-color: colors.$blue;
}

body {
    margin: 0;
    font-family: 'MyanmarSangamMN', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: (3 / 100) * -1em;
}
