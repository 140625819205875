$regular: 400;
$bold: 800;

@font-face {
    font-family: 'MyanmarSangamMN';
    src: url(../fonts/MyanmarSangamMN.woff) format('woff');
    font-weight: $regular;
}

@font-face {
    font-family: 'MyanmarSangamMN';
    src: url(../fonts/MyanmarSangamMN-Bold.woff) format('woff');
    font-weight: $bold;
}
