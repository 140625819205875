.quoteSquiggle {
    background-image: url('../../images/quote-squiggle.svg');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
}

.headerSquiggle {
    background-image: url('../../images/header-squiggle--blue.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
}
