@use '../../styles/breakpoints.scss';

.root {
  padding: (35 / 1400) * 100vw;
  margin: 0 (30 / 1440) * 100vw;
  background-position: center;
  background-size: contain;
  display: inline-block;
  background-repeat: no-repeat;
  vertical-align: middle;

  &__white1 {
    background-image: url('../../images/Squiggle-1--white.svg');
  }

  &__white2 {
    background-image: url('../../images/Squiggle-2--white.svg');
  }

  &__white3 {
    background-image: url('../../images/Squiggle-3--white.svg');
  }

  &__blue1 {
    background-image: url('../../images/Squiggle-1--blue.svg');
  }

  @include breakpoints.medium-plus {
    padding: (25 / 1400) * 100vw;
    margin: 0 (20 / 1440) * 100vw;
  }

  &--is-small {
    @include breakpoints.large {
      padding: (25 / 1400) * 50vw;
      margin: 0 (20 / 1440) * 50vw;
    }
  }
}
