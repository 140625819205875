@use '../../styles/typography.scss';

.cover {
  width: 80vw;
  margin: 0 auto;
  max-width: 400px;
  border: 10px solid white;
  border-radius: 10px;
}

.title {
  font-weight: typography.$regular;
  padding-bottom: 30px;
}
